export default {
  computed: {
    isXxs() {
      return this.$store.state.mobile.isXxs
    },
    isXs() {
      return this.$store.state.mobile.isXs
    },
    isSm() {
      return this.$store.state.mobile.isSm
    },
    isMd() {
      return this.$store.state.mobile.isMd
    },
    isLg() {
      return this.$store.state.mobile.isLg
    },
    isXl() {
      return this.$store.state.mobile.isXl
    },
    isMobile() {
      return this.$store.state.mobile.isMobile
    },
    isTablet() {
      return this.$store.state.mobile.isTablet
    },
    isPc() {
      return navigator?.userAgent?.match(/Windows/i) || navigator?.userAgent?.match(/Macintosh/i)
    },
    isAndroid() {
      return navigator?.userAgent?.match(/Android/i)
    },
    isIOS() {
      return navigator?.userAgent?.match(/iPhone|iPad|iPod/i)
    },
  },
}
